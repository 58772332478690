import { AnyAction } from "redux";
import {
  SET_REAL_ESTATE_REPORT_FILTERS,
  SET_REAL_ESTATE_REPORT_H3_CELLS,
  SET_REAL_ESTATE_REPORT_LOADING,
  SET_REAL_ESTATE_REPORT_RESPONSE,
} from "./actions";

const initialState = {
  // filtersParams: {
  //   country: "Ukraine",
  //   country_id: "uk",
  //   sity: "Київ",
  //   city_uuid: "4f864869-1e00-48c4-8ee1-7214c6cd03c2",
  // },
  h3: {
    cells: null,
    resolution: null,
  },
  loading: false,
  response: null,
};

const reportReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_REAL_ESTATE_REPORT_FILTERS:
      return {
        ...state,
        filtersParams: action.payload,
      };
    case SET_REAL_ESTATE_REPORT_H3_CELLS:
      return {
        ...state,
        h3: action.payload,
      };
    case SET_REAL_ESTATE_REPORT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_REAL_ESTATE_REPORT_RESPONSE:
      return {
        ...state,
        response: action.payload,
      };
    default:
      return state;
  }
};

export default reportReducer;
