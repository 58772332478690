export interface VisualizationMode {
  field: "avg_price_per_sqm" | "avg_area" | "count";
  label: string;
  baseColor: string;
}

export const VISUALIZATION_MODES: VisualizationMode[] = [
  {
    field: "avg_price_per_sqm",
    label: "Price per m²",
    baseColor: "#1E88E5",
  },
  {
    field: "avg_area",
    label: "Average Area",
    baseColor: "#43A047",
  },
  {
    field: "count",
    label: "Properties Count",
    baseColor: "#FB8C00",
  },
];
