import { APICore } from "./apiCore";

const api = new APICore();

function getTickersData(params: { [key: string]: string }) {
  const baseUrl = "/api/v1/tickers";
  return api.get(`${baseUrl}`, params);
}

function getStockMarketOptions(params: { [key: string]: string }) {
  const baseUrl = "/api/v1/ticker_options";
  return api.get(`${baseUrl}`, params);
}

export { getTickersData, getStockMarketOptions };
