import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import layoutSaga from "./layout/saga";
import testSaga from "./filtersTest/saga";
import mapSaga from "./map/saga";

export default function* rootSaga() {
  yield all([authSaga(), layoutSaga(), testSaga(), mapSaga()]);
}
