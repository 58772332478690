import { all, fork, takeEvery, put, select, call } from "redux-saga/effects";
import { polygonToCells, getResolution } from "h3-js";
import { getCells } from "../../helpers/api/realEstate";

//utils
import { hexResolution } from "../../utils/hexResolution";

//types
import { MapTypes } from "./constants";
import type { SagaIterator } from "@redux-saga/core";
import { LatLngBoundsLiteral, LatLngBounds } from "leaflet";
import { AxiosError } from "axios";

// let getHexControler: AbortController | null = null;

// // the key is the resolution of the map, the value is the corresponding H3 resolution
// const resolution: { [key: number]: number } = {
//   // 4: 1,
//   // 5: 1,
//   // 6: 2,
//   // 7: 3,
//   8: 4,
//   9: 4,
//   10: 5,
//   11: 6,
//   12: 7,
//   13: 7,
//   14: 8,
//   15: 8,
// };

function* onGetCells(action: { type: MapTypes; payload: number }): SagaIterator {
  const zoom = action.payload;
  if (hexResolution(zoom) < 4 || hexResolution(zoom) > 8) return;

  const boundaries = yield call(expandBounds, 1.3);

  try {
    // if (getHexControler) {
    //   getHexControler.abort();
    // }

    // getHexControler = new AbortController();
    const cells = yield call(getCells, { polygon: boundaries, res: hexResolution(zoom) });

    yield put({ type: MapTypes.SET_HEX_DATA, payload: cells.data });
  } catch (error) {
    const { name, message } = error as AxiosError;
    console.error(`${name}: ${message}`);
  }
}

function* seletedCellsHandler(action: { type: MapTypes; payload: string }): SagaIterator {
  const { type, payload } = action;
  const { selectedCells } = yield select((state) => state.mapReducer);
  let { res, cells } = selectedCells;

  if (type === MapTypes.SELECT_CELL) {
    if (res !== getResolution(payload)) {
      res = getResolution(payload);
      cells = [];
    }

    cells = [...cells, payload];
  }

  if (type === MapTypes.UNSELECT_CELL) {
    cells = cells.filter((cell: string) => cell !== payload);
  }

  yield put({ type: MapTypes.SELECTED_CELLS_HANDLER, payload: { res, cells } });
}

function* validateSelectedCells(action: {
  type: MapTypes;
  payload: number | LatLngBounds;
}): SagaIterator {
  const { payload } = action;
  const { selectedCells } = yield select((state) => state.mapReducer);
  let { res, cells } = selectedCells;

  if (res && typeof payload === "number") {
    const resolution = hexResolution(payload);
    if (resolution >= res + 3 || resolution <= res - 3) {
      yield put({
        type: MapTypes.SELECTED_CELLS_HANDLER,
        payload: { res: null, cells: [] },
      });
    }
  }

  if (cells.length) {
    const boundaries = yield call(expandBounds, 0.2);
    const cellsInMap = polygonToCells(boundaries, res);

    cells = cells.map((cell: string) => (cellsInMap.includes(cell) ? cell : null));

    if (cells.includes(null)) {
      cells = cells.filter((cell: string) => cell);
      res = cells.length ? res : null;
      yield put({
        type: MapTypes.SELECTED_CELLS_HANDLER,
        payload: { res, cells },
      });
    }
  }
}

function* expandBounds(multiplier: number): SagaIterator {
  const { mapBoundaries } = yield select((state) => state.mapReducer);
  const expandBounds = mapBoundaries.pad(multiplier);
  const NW = expandBounds.getNorthWest();
  const NE = expandBounds.getNorthEast();
  const SE = expandBounds.getSouthEast();
  const SW = expandBounds.getSouthWest();

  const bounds: LatLngBoundsLiteral = [
    [NW.lat, NW.lng],
    [NE.lat, NE.lng],
    [SE.lat, SE.lng],
    [SW.lat, SW.lng],
  ];
  return bounds;
}

export function* watchCells() {
  yield takeEvery(MapTypes.GET_CELLS, onGetCells);
}

export function* watchSelectCell() {
  yield takeEvery(MapTypes.SELECT_CELL, seletedCellsHandler);
}

export function* watchUnselectCell() {
  yield takeEvery(MapTypes.UNSELECT_CELL, seletedCellsHandler);
}

export function* watchSetBoundery() {
  yield takeEvery(MapTypes.SET_MAP_BOUNDARIES, validateSelectedCells);
}

export function* watchSetZoom() {
  yield takeEvery(MapTypes.SET_MAP_ZOOM, validateSelectedCells);
}

function* mapSaga() {
  yield all([
    fork(watchCells),
    fork(watchSelectCell),
    fork(watchUnselectCell),
    fork(watchSetBoundery),
    fork(watchSetZoom),
  ]);
}

export default mapSaga;
