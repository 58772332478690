export enum Country {
  SELECTED_OPTION = "@@filter/country/SELECTED_OPTION",
  OPTIONS = "@@filter/country/OPTIONS",
  LOADING = "@@filter/country/LOADING",
  ERROR = "@@filter/country/ERROR",
}

export enum City {
  SELECTED_OPTION = "@@filter/city_uuid/SELECTED_OPTION",
  OPTIONS = "@@filter/city_uuid/OPTIONS",
  LOADING = "@@filter/city_uuid/LOADING",
  ERROR = "@@filter/city_uuid/ERROR",
}

export enum StartDate {
  SELECTED_OPTION = "@@filter/start_date/SELECTED_OPTION",
}

export enum EndDate {
  SELECTED_OPTION = "@@filter/end_date/SELECTED_OPTION",
}

export enum AdTypes {
  SELECTED_OPTION = "@@filter/ad_types/SELECTED_OPTION",
  OPTIONS = "@@filter/ad_types/OPTIONS",
  LOADING = "@@filter/ad_types/LOADING",
  ERROR = "@@filter/ad_types/ERROR",
}

export enum AdSubTypes {
  SELECTED_OPTION = "@@filter/ad_sub_types/SELECTED_OPTION",
  OPTIONS = "@@filter/ad_sub_types/OPTIONS",
  LOADING = "@@filter/ad_sub_types/LOADING",
  ERROR = "@@filter/ad_sub_types/ERROR",
}

export enum AppRooms {
  SELECTED_OPTION = "@@filter/app_rooms/SELECTED_OPTION",
  OPTIONS = "@@filter/app_rooms/OPTIONS",
  LOADING = "@@filter/app_rooms/LOADING",
  ERROR = "@@filter/app_rooms/ERROR",
}
