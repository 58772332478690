export enum MapTypes {
  SET_MAP_ZOOM = "@@map/SET_MAP_ZOOM",
  SET_MAP_BOUNDARIES = "@@map/SET_MAP_BOUNDARIES",
  GET_CELLS = "@@map/GET_CELLS",
  SET_HEX_DATA = "@@map/SET_HEX_DATA",
  SELECT_CELL = "@@map/SELECT_CELL",
  UNSELECT_CELL = "@@map/UNSELECT_CELL",
  SELECTED_CELLS_HANDLER = "@@map/SELECTED_CELLS_HANDLER",
  SET_VISUALIZATION_MODE = "@@map/SET_VISUALIZATION_MODE",
}
