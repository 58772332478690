export const SET_REAL_ESTATE_HOMES = "SET_REAL_ESTATE_HOMES";

interface filtersParams {
  [key: string]: string;
}

export const setRealEstateHomes = (filtersParams: filtersParams) => ({
  type: SET_REAL_ESTATE_HOMES,
  payload: filtersParams,
});
