import { AnyAction } from "redux";
import { MapTypes } from "./constants";
import { VISUALIZATION_MODES } from "../../types/map/visualizationTypes";

const initialState = {
  mapZoom: 10,
  mapBoundaries: null,
  hexData: null,
  selectedCells: { res: null, cells: [] },
  visualizationMode: VISUALIZATION_MODES[0],
};

const mapReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case MapTypes.SET_MAP_ZOOM:
      return {
        ...state,
        mapZoom: action.payload,
      };
    case MapTypes.SET_MAP_BOUNDARIES:
      return {
        ...state,
        mapBoundaries: action.payload,
      };
    case MapTypes.SET_HEX_DATA:
      return {
        ...state,
        hexData: action.payload,
      };
    case MapTypes.SELECTED_CELLS_HANDLER:
      return {
        ...state,
        selectedCells: action.payload,
      };
    case MapTypes.SET_VISUALIZATION_MODE:
      return {
        ...state,
        visualizationMode: action.payload,
      };
    default:
      return state;
  }
};

export default mapReducer;
