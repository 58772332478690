import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import filterReducer from "./filters/reducers";
import reportReducer from "./report/reducers";
import { filtersTestReducer } from "./filtersTest/reducers";
import mapReducer from "./map/reducer";

export default combineReducers({
  Auth,
  Layout,
  filterReducer,
  reportReducer,
  filtersTestReducer,
  mapReducer,
});
