import { AnyAction } from "redux";
import { SET_REAL_ESTATE_HOMES } from "./actions";
import { addMonths, format } from "date-fns";

const initialState = {
  filtersParams: {
    country: "ua",
    city_uuid: "4f864869-1e00-48c4-8ee1-7214c6cd03c2",
    start_date: `${format(addMonths(new Date(), -3), "dd-MM-yyyy")}`,
    end_date: `${format(new Date(), "dd-MM-yyyy")}`,
  },
};

const filterReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_REAL_ESTATE_HOMES:
      return {
        ...state,
        filtersParams: action.payload,
      };
    default:
      return state;
  }
};

export default filterReducer;
