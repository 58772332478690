//types
import { Country, City, StartDate, EndDate, AdTypes, AdSubTypes, AppRooms } from "./constants";
import {
  PageType,
  SetSearchParamsActionType,
  FilterSelectedOptionType,
  FilterOptionsType,
  FilterLoadingType,
  FilterErrorType,
  FiltersType,
} from "../../types/redux/filtersTest/actions";

export const PAGE = "PAGE";
export const HOMES_SEARCH_PARAMS = "HOMES_SEARCH_PARAMS";

export const setSearchParamsAction: SetSearchParamsActionType = (payload) => ({
  type: HOMES_SEARCH_PARAMS,
  payload: payload,
});

export const filterPage: PageType = (payload) => ({
  type: PAGE,
  payload: payload,
});

export const filterSelectedOption: FilterSelectedOptionType = (actionType, option) => ({
  type: actionType,
  payload: option,
});

export const filterOptions: FilterOptionsType = (actionType, options) => ({
  type: actionType,
  payload: options,
});

export const filterLoading: FilterLoadingType = (actionType, loading) => ({
  type: actionType,
  payload: loading,
});

export const filterError: FilterErrorType = (actionType, error) => ({
  type: actionType,
  payload: error,
});

export const FiltersActions: FiltersType = {
  country: {
    selectedOptionAction: (option) => filterSelectedOption(Country.SELECTED_OPTION, option),
    optionsAction: (options) => filterOptions(Country.OPTIONS, options),
    loadingAction: (loading) => filterLoading(Country.LOADING, loading),
    errorAction: (error) => filterError(Country.ERROR, error),
  },
  city_uuid: {
    selectedOptionAction: (option) => filterSelectedOption(City.SELECTED_OPTION, option),
    optionsAction: (options) => filterOptions(City.OPTIONS, options),
    loadingAction: (loading) => filterLoading(City.LOADING, loading),
    errorAction: (error) => filterError(City.ERROR, error),
  },
  start_date: {
    selectedOptionAction: (option) => filterSelectedOption(StartDate.SELECTED_OPTION, option),
  },
  end_date: {
    selectedOptionAction: (option) => filterSelectedOption(EndDate.SELECTED_OPTION, option),
  },
  ad_types: {
    selectedOptionAction: (option) => filterSelectedOption(AdTypes.SELECTED_OPTION, option),
    optionsAction: (options) => filterOptions(AdTypes.OPTIONS, options),
    loadingAction: (loading) => filterLoading(AdTypes.LOADING, loading),
    errorAction: (error) => filterError(AdTypes.ERROR, error),
  },
  ad_sub_types: {
    selectedOptionAction: (option) => filterSelectedOption(AdSubTypes.SELECTED_OPTION, option),
    optionsAction: (options) => filterOptions(AdSubTypes.OPTIONS, options),
    loadingAction: (loading) => filterLoading(AdSubTypes.LOADING, loading),
    errorAction: (error) => filterError(AdSubTypes.ERROR, error),
  },
  app_rooms: {
    selectedOptionAction: (option) => filterSelectedOption(AppRooms.SELECTED_OPTION, option),
    optionsAction: (options) => filterOptions(AppRooms.OPTIONS, options),
    loadingAction: (loading) => filterLoading(AppRooms.LOADING, loading),
    errorAction: (error) => filterError(AppRooms.ERROR, error),
  },
};
