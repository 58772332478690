import { AnyAction } from "redux";
import { Country, City, StartDate, EndDate, AdTypes, AdSubTypes, AppRooms } from "./constants";
import { countries } from "../../constants/countriesData";
import { PAGE, HOMES_SEARCH_PARAMS } from "./actions";

const initialState = {
  page: null,
  searchParams: "",
  country: {
    selectedOption: null,
    options: countries,
    loading: false,
    error: false,
  },
  city_uuid: {
    selectedOption: null,
    options: null,
    loading: false,
    error: false,
  },
  start_date: {
    selectedOption: null,
  },
  end_date: {
    selectedOption: null,
  },
  ad_types: {
    selectedOption: null,
    options: null,
    loading: false,
    error: false,
  },
  ad_sub_types: {
    selectedOption: null,
    options: null,
    loading: false,
    error: false,
  },
  app_rooms: {
    selectedOption: null,
    options: null,
    loading: false,
    error: false,
  },
};

const filtersTestReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    //page block
    case HOMES_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.payload,
      };
    case PAGE:
      return {
        ...state,
        page: action.payload,
      };

    //country block

    case Country.SELECTED_OPTION:
      return {
        ...state,
        country: { ...state.country, selectedOption: action.payload },
      };
    case Country.OPTIONS:
      return {
        ...state,
        country: { ...state.country, options: action.payload },
      };
    case Country.LOADING:
      return {
        ...state,
        country: { ...state.country, loading: action.payload },
      };
    case Country.ERROR:
      return {
        ...state,
        country: { ...state.country, error: action.payload },
      };

    //city_uuid block

    case City.SELECTED_OPTION:
      return {
        ...state,
        city_uuid: { ...state.city_uuid, selectedOption: action.payload },
      };
    case City.OPTIONS:
      return {
        ...state,
        city_uuid: { ...state.city_uuid, options: action.payload },
      };
    case City.LOADING:
      return {
        ...state,
        city_uuid: { ...state.city_uuid, loading: action.payload },
      };
    case City.ERROR:
      return {
        ...state,
        city_uuid: { ...state.city_uuid, error: action.payload },
      };

    // date block

    case StartDate.SELECTED_OPTION:
      return {
        ...state,
        start_date: { ...state.start_date, selectedOption: action.payload },
      };
    case EndDate.SELECTED_OPTION:
      return {
        ...state,
        end_date: { ...state.end_date, selectedOption: action.payload },
      };

    // ad_types blcok

    case AdTypes.SELECTED_OPTION:
      return {
        ...state,
        ad_types: { ...state.ad_types, selectedOption: action.payload },
      };
    case AdTypes.OPTIONS:
      return {
        ...state,
        ad_types: { ...state.ad_types, options: action.payload },
      };
    case AdTypes.LOADING:
      return {
        ...state,
        ad_types: { ...state.ad_types, loading: action.payload },
      };
    case AdTypes.ERROR:
      return {
        ...state,
        ad_types: { ...state.ad_types, error: action.payload },
      };

    //ad_sub_types block

    case AdSubTypes.SELECTED_OPTION:
      return {
        ...state,
        ad_sub_types: { ...state.ad_sub_types, selectedOption: action.payload },
      };
    case AdSubTypes.OPTIONS:
      return {
        ...state,
        ad_sub_types: { ...state.ad_sub_types, options: action.payload },
      };
    case AdSubTypes.LOADING:
      return {
        ...state,
        ad_sub_types: { ...state.ad_sub_types, loading: action.payload },
      };
    case AdSubTypes.ERROR:
      return {
        ...state,
        ad_sub_types: { ...state.ad_sub_types, error: action.payload },
      };

    //app_rooms block

    case AppRooms.SELECTED_OPTION:
      return {
        ...state,
        app_rooms: { ...state.app_rooms, selectedOption: action.payload },
      };
    case AppRooms.OPTIONS:
      return {
        ...state,
        app_rooms: { ...state.app_rooms, options: action.payload },
      };
    case AppRooms.LOADING:
      return {
        ...state,
        app_rooms: { ...state.app_rooms, loading: action.payload },
      };
    case AppRooms.ERROR:
      return {
        ...state,
        app_rooms: { ...state.app_rooms, error: action.payload },
      };

    default:
      return state;
  }
};

export { filtersTestReducer };
