import { all, fork, put, takeEvery, call, select, takeLatest, take } from "redux-saga/effects";
import removeAccents from "remove-accents";

import { getCityList, getRealEstateOptions } from "../../helpers";

//types
import { Country, City, AdTypes, AdSubTypes, AppRooms } from "./constants";
import { SagaIterator } from "@redux-saga/core";
import { TypeList } from "../../types/redux/filtersTest/actions";
import { SingleValue } from "react-select";
import { FilterOption } from "../../types/components/FilterSelectTest";
import { CityList } from "../../types/api/realEstateResponseTypes";
import { FiltersList } from "../../types/redux/filtersTest/actions";
import { AxiosError } from "axios";
import { RootState } from "../store";

interface filtersType {
  name: string;
  loading: string;
  selectedOption: string;
  error: string;
}

const filtersArr: filtersType[] = [
  {
    name: "country",
    loading: Country.LOADING,
    selectedOption: Country.SELECTED_OPTION,
    error: Country.ERROR,
  },
  {
    name: "city_uuid",
    loading: City.LOADING,
    selectedOption: City.SELECTED_OPTION,
    error: City.ERROR,
  },
  {
    name: "ad_types",
    loading: AdTypes.LOADING,
    selectedOption: AdTypes.SELECTED_OPTION,
    error: AdTypes.ERROR,
  },
  {
    name: "ad_sub_types",
    loading: AdSubTypes.LOADING,
    selectedOption: AdSubTypes.SELECTED_OPTION,
    error: AdSubTypes.ERROR,
  },
  {
    name: "app_rooms",
    loading: AppRooms.LOADING,
    selectedOption: AppRooms.SELECTED_OPTION,
    error: AppRooms.ERROR,
  },
];

const pageFilters: { homes: filtersType[]; report: filtersType[] } = {
  homes: filtersArr,
  report: filtersArr.slice(0, 2),
};

let getCityListControler: AbortController | null = null;
let getRealEstateOptionsControler: AbortController | null = null;

// main functions

function* onSelectCountry(action: {
  type: TypeList;
  payload: { value: string; label: string };
}): SagaIterator {
  const { value } = action.payload;
  const { page } = yield select((state: RootState) => state.filtersTestReducer);
  if (!page) {
    yield take("PAGE");
  }
  yield call(setFiltersLoadingTrue, "country");
  yield call(setError, "country", false);
  yield call(getCityListHandler, value);
}

function* onSelectCity(action: { type: TypeList; payload: { value: string; label: string } }) {
  if (!action.payload) return;
  const { value } = action.payload;
  const { page } = yield select((state: RootState) => state.filtersTestReducer);
  if (!page) {
    yield take("PAGE");
  }
  yield call(setFiltersLoadingTrue, "city_uuid");
  yield call(setError, "city_uuid", false);
  if (page === "homes") yield call(getRealEstateOptionsHandler, value);
}

// support functions

function* setFiltersLoadingTrue(filter: FiltersList): SagaIterator {
  const { page } = yield select((state: RootState) => state.filtersTestReducer);
  const filters = pageFilters[page as "homes" | "report"];
  const index = filters.findIndex((elem) => elem.name === filter);
  const filtersState = yield select((state) => state.filtersTestReducer);
  for (let idx = 0; idx < filters.length; idx++) {
    if (idx <= index) continue;
    const { name, loading, selectedOption } = filters[idx];
    if (!filtersState[name].loading) yield put({ type: loading, payload: true });
    if (filtersState[name].selectedOption) yield put({ type: selectedOption, payload: null });
  }
}

function* setFiltersLoadingFalse(filter: FiltersList): SagaIterator {
  const { page } = yield select((state: RootState) => state.filtersTestReducer);
  const filters = pageFilters[page as "homes" | "report"];
  const index = filters.findIndex((elem) => elem.name === filter);
  yield put({ type: filters[index].loading, payload: false });
}

function* setError(filter: FiltersList, errorState: boolean): SagaIterator {
  const { page } = yield select((state: RootState) => state.filtersTestReducer);
  const filters = pageFilters[page as "homes" | "report"];
  const index = filters.findIndex((elem) => elem.name === filter);
  const filtersState = yield select((state) => state.filtersTestReducer);
  for (let idx = 0; idx < filters.length; idx++) {
    if (idx <= index) continue;
    const { name, error } = filters[idx];
    if (filtersState[name].error !== errorState) yield put({ type: error, payload: errorState });
  }
}

function* getCityListHandler(value: string): SagaIterator {
  try {
    if (getCityListControler) {
      getCityListControler.abort();
    }

    getCityListControler = new AbortController();

    const { data } = yield call(getCityList, value, {}, { signal: getCityListControler.signal });
    const options: SingleValue<FilterOption>[] = [];
    data.forEach((option: CityList) => {
      if (!option.name) return;
      options.push({
        value: option.id,
        label: removeAccents(option.name),
      });
    });

    yield call(setFiltersLoadingFalse, "city_uuid");
    yield put({ type: City.OPTIONS, payload: options });
  } catch (error) {
    const { name, message } = error as AxiosError;
    console.error(`${name}: ${message}`);
    yield call(setError, "country", true);
  }
}

function* getRealEstateOptionsHandler(value: string): SagaIterator {
  const { country, start_date, end_date } = yield select((state) => ({
    country: state.filtersTestReducer.country.selectedOption.value,
    start_date: state.filtersTestReducer.start_date.selectedOption,
    end_date: state.filtersTestReducer.end_date.selectedOption,
  }));

  try {
    if (getRealEstateOptionsControler) {
      getRealEstateOptionsControler.abort();
    }

    getRealEstateOptionsControler = new AbortController();

    const { data } = yield call(
      getRealEstateOptions,
      {
        country,
        city_uuid: value,
        start_date,
        end_date,
      },
      { signal: getRealEstateOptionsControler.signal }
    );
    const adTypes = data.ad_types.map((type: string) => {
      if (!type) return null;
      return {
        label: type
          .replace(/_/g, " ")
          .split(" ")
          .map((w: any) => w.charAt(0).toUpperCase() + w.slice(1))
          .join(" "),
        value: type,
      };
    });

    yield call(setFiltersLoadingFalse, "ad_types");
    yield put({ type: AdTypes.OPTIONS, payload: adTypes });

    const adSubTypes = data.ad_sub_types.map((type: string) => {
      if (!type) return null;
      return {
        label: type
          .split(" ")
          .map((w: any) => w.charAt(0).toUpperCase() + w.slice(1))
          .join(" "),
        value: type,
      };
    });

    yield call(setFiltersLoadingFalse, "ad_sub_types");
    yield put({ type: AdSubTypes.OPTIONS, payload: adSubTypes });

    const appRooms = data.app_rooms
      .map((type: string) => {
        if (!type) return null;
        return { label: type, value: type === "All" ? type : type.replace(/\D/g, "") };
      })
      .filter((option: FilterOption) => option)
      .sort((a: FilterOption, b: FilterOption) => (a.label < b.label ? -1 : 1));

    yield call(setFiltersLoadingFalse, "app_rooms");
    yield put({ type: AppRooms.OPTIONS, payload: appRooms });
  } catch (error) {
    console.log(error);
  }
}

// watch fucntions

export function* watchCountry() {
  yield takeLatest(Country.SELECTED_OPTION, onSelectCountry);
}

export function* watchCity() {
  yield takeEvery(City.SELECTED_OPTION, onSelectCity);
}

function* testSaga() {
  yield all([fork(watchCountry), fork(watchCity)]);
}

export default testSaga;
